.homework {
  background-color: #f7f7fc;
  width: 100%;
  height: 100%;

  &__container {
    width: calc(100vw - 24px);
    height: calc(100vh - 74px);
    padding: 12px;
    display: flex;
    flex-direction: row;
    gap: 12px;

    &__left {
      display: flex;
      flex: 2;
      height: 100%;
      background-color: #ffffff;
      border-color: rgba(48, 57, 105, 0.15);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      flex-direction: column;

      &__section-title {
        background-color: #e1e1e7;
        font-weight: bold;
        padding: 8px 18px;
        font-size: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }

      &__top {
        flex: 2;

        &__body {
          padding: 16px;

          &__title {
            font-weight: bold;
            font-size: 18px;
          }

          &__description {
            font-size: 14px;
            white-space: break-spaces;
            font-family: Studio-Feixen-Sans;
          }
        }
      }

      &__bottom {
        flex: 3;

        &__body {
          padding: 0 16px 0 16px;

          &__description {
            font-size: 14px;
            font-family: Studio-Feixen-Sans;
            white-space: break-spaces;
            font-family: Studio-Feixen-Sans;
            line-height: 2;
          }
        }
      }
    }

    &__right {
      display: flex;
      flex: 3;
      flex-direction: column;
      gap: 12px;

      &__top {
        flex: 3;
        background-color: #051a2c;
        border-radius: 4px;

        &__header {
          border-radius: 4px;
          background-color: #203147;

          &__file {
            padding: 10px 14px;
            border-top-left-radius: 4px;
            display: inline-block;
            color: #ffffff;
            background-color: #051a2c;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0.6px;
            user-select: none;
            cursor: pointer;
          }
        }

        &__code {
          height: calc(100% - 34.5px);
          position: relative;

          &__actions {
            position: absolute;
            bottom: 8px;
            right: 12px;
            display: flex;
            flex-direction: row;
            gap: 8px;
          }
        }
      }

      &__bottom {
        flex: 2;
        background-color: #051a2c;
        border-radius: 4px;

        &__log {
          $viewport-height: 100vh;
          $header-height: 50px;
          $margin-1: 24px;
          $margin-2: 12px;

          $max-height: calc(
            (
                #{$viewport-height} - #{$header-height} - #{$margin-1} - #{$margin-2}
              ) / 5 * 2 - 34.5px
          );

          letter-spacing: 1.1px;
          color: #cfa600;
          white-space: break-spaces;
          font-family: Studio-Feixen-Sans;
          font-size: 16px;
          display: flex;
          height: 100%;
          max-height: $max-height;
          overflow: auto;
          border-radius: 4px;
          padding: 0 16px;

          pre {
            width: 100%;
            word-break: break-all;
            white-space: break-spaces;
          }
        }
      }
    }
  }
}

.congrat-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 5em;
    margin-bottom: 18px;
  }

  &__continue-text {
    margin-top: 30px;
    font-weight: bold;
    margin-bottom: 15px;
  }
}
