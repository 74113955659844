.page-header-bar {
  height: 54px;
  max-height: 54px;
  background-color: #ffffff;
  box-shadow: rgba(61, 66, 81, 0.2) 0px 0px 1px 0px,
    rgba(61, 66, 81, 0.3) 0px 1px 4px -1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px 0 12px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  &__home-btn {
    height: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &__label {
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__search {
    margin-left: 50px !important;
    width: 400px;

    .MuiIconButton-root {
      padding: 0;
    }
    .MuiInputBase-root {
      border-radius: 20px !important;
    }
    .MuiInputBase-input {
      padding: 6px;
    }
  }

  &__menu-info {
    direction: rtl;
    padding: 12px;
    max-width: 200px;
    line-break: auto;
    font-size: 12px;
    color: dimgray;
    display: flex;
    flex-direction: column;
    gap: 6px;

    &__row {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
    }
  }
}
