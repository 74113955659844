@font-face {
  font-family: Studio-Feixen-Sans;
  src: url(../public/fonts/StudioFeixenSans-Regular.ttf);
}

body {
  overflow: hidden;
}

.App {
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  overflow-y: hidden;
  background-color: #f7f7fc;
  font-family: Studio-Feixen-Sans;
}

.MuiTextField-root {
  display: block;
  margin-bottom: 12px !important;
}

.MuiInputBase-input {
  padding: 12px !important;
  width: 100%;
}
