.hw-row-container {
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;

  &:hover {
    background-color: #e8e8ed;
  }

  &__icon {
    width: 24px;
    height: 24px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 0.9em;
    }
  }

  &__text {
    font-weight: bold;
  }

  &__xp {
    margin-left: auto;
  }

  &__submit-status {
    margin-left: auto;
  }
}

.disabled {
  cursor: not-allowed;
}
